import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const PrivacyPolicy = () => {
        const navigate = useNavigate();
    
        const handleBackToLogin = () => navigate('/user-details')
    return (
        <div className="p-6 max-w-3xl mx-auto" style={{ paddingLeft: '15px' }}>
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p>Xpanse (“we” or “us”) values your privacy. This Privacy Policy explains the types of personal information we collect when you use our kiosk, how we use and share that data, and the measures we take to protect it. Please read this policy carefully before using our kiosk.</p>

            <h2 className="text-xl font-semibold mt-4">1. Definitions</h2>
            <ul className="list-disc ml-6">
                <li><strong>User:</strong> The natural person who accesses and uses our kiosk.</li>
                <li><strong>Data:</strong> Any information provided by you or collected automatically when you use the kiosk, including personal information such as your name and mobile number.</li>
                <li><strong>Cookies, Pixels, and Similar Technologies:</strong> Small data files or techniques used to enhance user experience and collect usage statistics.</li>
                <li><strong>Service Providers:</strong> Third-party entities engaged to process or store Data on our behalf.</li>
                <li><strong>Partners:</strong> Select third parties with whom we have contractual arrangements.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">2. What Data Do We Collect?</h2>
            <p>Because the kiosk is designed for simplicity and convenience, the Data we collect is minimal:</p>
            <ul className="list-disc ml-6">
                <li><strong>Personal Information:</strong> Your name and mobile phone number (required); email address (optional).</li>
                <li><strong>Technical Information:</strong> IP address, device type, and usage data for performance and analytics.</li>
                <li><strong>Payment Data:</strong> Processed externally via a third-party gateway; we do not store it.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">3. How We Collect Data</h2>
            <ul className="list-disc ml-6">
                <li>Information You Provide: Name, mobile number, and email address (optional).</li>
                <li>Automatic Collection: IP address, session timestamps, and usage statistics.</li>
                <li>Third-Party Processing: Payment details handled by secure third-party payment processors.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">4. How We Use Your Data</h2>
            <ul className="list-disc ml-6">
                <li>To Process Orders: Used for order confirmation and updates.</li>
                <li>To Enhance Security: For system performance and issue resolution.</li>
                <li>For Analytics: Aggregated usage data to improve services.</li>
                <li>For Legal Compliance: To meet legal and regulatory obligations.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">5. Data Sharing</h2>
            <ul className="list-disc ml-6">
                <li>With Service Providers and Partners: For order processing and analytics.</li>
                <li>For Legal Compliance: If required by law.</li>
                <li>Payment Processing: Managed exclusively by secure third-party payment gateways.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">6. Data Security</h2>
            <p>We implement appropriate measures to safeguard your Data against unauthorized access or disclosure.</p>

            <h2 className="text-xl font-semibold mt-4">7. Data Retention</h2>
            <p>Your Data is retained only as necessary for order processing and legal compliance.</p>

            <h2 className="text-xl font-semibold mt-4">8. Your Rights and Choices</h2>
            <ul className="list-disc ml-6">
                <li>Access, correct, or delete your Data.</li>
                <li>Withdraw consent or opt out of communications.</li>
                <li>Restrict Data processing.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">9. Where We Store and Process Your Data</h2>
            <p>Your Data is stored on secure servers located in India.</p>

            <h2 className="text-xl font-semibold mt-4">10. Changes to This Privacy Policy</h2>
            <p>We may update this policy as needed, and any changes will be posted on the kiosk interface.</p>

            <h2 className="text-xl font-semibold mt-4">11. Contact Us</h2>
            <p>If you have any questions, please contact us at:</p>
            <p>Email: <a href="mailto:contact@xpansecafe.com" className="text-blue-600">contact@xpansecafe.com</a></p>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: 2,
                }}
            >
                <Button
                    variant="contained"
                    onClick={handleBackToLogin}
                    sx={{
                        backgroundColor: "#1565C0",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        marginBottom: '10px',
                        width: "300px",
                        "&:hover": {
                            backgroundColor: "#0D47A1",
                        },
                    }}
                >
                    Back
                </Button>
            </Box>

        </div>
    );
};

export default PrivacyPolicy;
