import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const OrderConfirmation = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [seconds, setSeconds] = useState(60);
    const navigate = useNavigate();
    const cartReadyTime = localStorage.getItem("cartReadyTime");
    const cart = JSON.parse(localStorage.getItem("cart"));
    const kioskUser = JSON.parse(localStorage.getItem("kioskUser"));
    const additionalCharges = JSON.parse(
        localStorage.getItem("additionalCharges")
    );
    const orderNumber = localStorage.getItem("orderNumber");

    const products = Array.isArray(selectedProducts) ? selectedProducts : [];

    const navigateToUserDetails = () => {
        localStorage.removeItem("additionalCharges");
        localStorage.removeItem("cart");
        localStorage.removeItem("cartId");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartReadyTime");
        localStorage.removeItem("kioskUser");
        localStorage.removeItem("orderAmount");
        localStorage.removeItem("orderId");
        localStorage.removeItem("orderNumber");
        navigate("/user-details");
    };

    useEffect(() => {
        // Start the countdown timer
        const timer = setInterval(() => {
            setSeconds(prev => prev - 1);
        }, 1000);

        // If the timer reaches 0, redirect the user
        if (seconds === 0) {
            navigate('/user-details');
        }

        // Cleanup the timer on component unmount or when the countdown finishes
        return () => clearInterval(timer);
    }, [seconds, navigate]);

    useEffect(() => {
        const products = JSON.parse(localStorage.getItem("cartItems")) || [];
        setSelectedProducts(products);
    }, []);

    return (

        <>
            <AppBar position="fixed" color="inherit" elevation={0} >
                <Toolbar sx={{ padding: '10px', background: '#dfeef5' }}>
                    {/* Brand Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <img src="/assets/brand-logo.png" alt="Brand Logo" style={{ height: 50, marginRight: 5 }} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Stack direction={'row'} justifyContent={'space-between'}
                sx={{
                    backgroundColor: "#FAEBD7",
                    minHeight: "100vh",
                    background: "#dfeef5",
                    marginTop: '83px',
                    paddingTop: '10px'
                }}
            >
                {/* LEFT BOX  */}
                <Box sx={{ width: '62%', bgcolor: 'white', boxSizing: 'border-box', borderTopRightRadius: '50px', }} p={2}>
                    <Stack direction={'row'} gap={2}>
                        <Box>
                            <img src="/assets/mark.png" alt="" />
                        </Box>
                        <Typography
                            variant="h5"
                            color={'#0c974b'}
                            fontWeight={'bold'}
                            sx={{ textAlign: "center", marginBottom: 3, fontSize: '26px' }}
                        >
                            Your Order Has Been Placed Successful!!
                        </Typography>
                    </Stack>

                    <Box sx={{ border: '2px solid #00b0ff', padding: '5px', borderRadius: '8px', bgcolor: '#dfeef5' }}>
                        <Typography fontSize={30} fontWeight={'bold'} color={'#0E4961'}>
                            Order id: {orderNumber}
                        </Typography>
                    </Box>

                    <Stack mt={2}>
                        <Typography textAlign={'left'} variant="h5" fontWeight={'semibold'} color={'#0E4961'} fontSize={20}>{kioskUser?.name}</Typography>
                        <Typography textAlign={'left'} variant="body1"
                            fontSize={14}>{kioskUser?.email}</Typography>
                        <Typography textAlign={'left'} variant="body1"
                            fontSize={16}>{kioskUser?.phone}</Typography>
                    </Stack>

                    <Box>
                        <Typography textAlign={'left'}
                            mt={2}
                            variant="h6" color={'#0E4961'}
                            sx={{ fontWeight: "bold" }}
                        >
                            Product Overview
                        </Typography>
                    </Box>


                    <Stack direction={'column'}>
                        {
                            products?.length > 0 && products?.map((item, index) => {
                                const isFirst = index === 0;
                                const isLast = index === products.length - 1;

                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            position: 'relative',
                                            borderTopLeftRadius: isFirst ? '20px' : '0',
                                            borderBottom: (!isFirst && !isLast) || (products?.length === 2) ? '2px dashed #e0e0e0' : 'none',
                                            borderTop: !isFirst && !isLast ? '2px dashed #e0e0e0' : 'none',
                                            borderTopRightRadius: isFirst ? '20px' : '0',
                                            bgcolor: '#dfeef5',
                                            height: '70px',
                                            borderBottomLeftRadius: isLast ? '20px' : '0',
                                            borderBottomRightRadius: isLast ? '20px' : '0',
                                            paddingTop: isFirst ? '10px' : '8px'
                                        }}
                                        p={2}
                                    >
                                        {item?.product?.isDelete && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                                                    zIndex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 'inherit',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                    fontSize: '1.2rem'
                                                }}
                                            >
                                                OUT OF STOCK
                                            </Box>
                                        )}
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Box sx={{ width: '45%' }}>
                                                <Typography fontWeight={'bold'} textAlign={'left'} color={'#0E4961'}>
                                                    {item?.product?.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '30%' }}>
                                                <Typography fontWeight={'bold'} textAlign={'center'} color={'#0E4961'}>
                                                    {item?.quantity}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '20%', }}>
                                                <Typography fontWeight={'bold'} textAlign={'right'} sx={{ color: '#00b0ff' }}>
                                                    ₹{(
                                                        (item?.rate || 0) * (item?.quantity || 1) +
                                                        item?.addons?.reduce(
                                                            (acc, item) => acc + (item?.offerPrice || 0),
                                                            0
                                                        ) * (item?.quantity || 1)
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                        <Grid container>
                                            <Typography
                                                sx={{
                                                    color: "#999",
                                                    fontSize: "0.9rem",
                                                    marginTop: "5px"
                                                }}
                                            >
                                                {item?.addons?.map((addon) => addon?.selectedValue)?.join(', ')}
                                            </Typography>
                                        </Grid>
                                    </Box>
                                )
                            })}
                    </Stack>


                    {/* Pickup Details */}
                    <Box
                        sx={{
                            marginTop: 2,
                            padding: 2,
                        }}
                    >
                        <Typography
                            variant="h5"
                            color={'#0E4961'}
                            fontWeight={'bold'}
                            sx={{ textAlign: "center", marginBottom: 3, fontSize: '26px' }}
                        >
                            We will announce your order id when ready!
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: "0 0 33%", background: '#dfeef5', marginRight: '2%'
                    }}
                >
                    <Typography variant='h5' mb={1} fontWeight={'bold'} textAlign={'left'} color={'#0E4961'}>Order Summary</Typography>
                    <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column', borderRadius: '20px' }}>
                        <CardContent sx={{ flex: 1 }}>
                            <Grid container justifyContent="space-between" p={1}>
                                <Grid item sx={{ textAlign: "left" }}>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">Item(s)</Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">Total MRP</Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">Total Tax Price</Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">Platform Charges</Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">Discounts</Typography>
                                </Grid>
                                <Grid item sx={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">
                                        {products?.reduce((acc, item) => acc + item?.quantity, 0)}
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">
                                        ₹ {cart?.totalTaxable.toFixed(2)}
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">
                                        ₹ {cart?.totalTaxAmount.toFixed(2)}
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">
                                        ₹ {((additionalCharges?.platformFees || 0) + (additionalCharges?.GSTPlatformFees || 0)).toFixed(2)}
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={'medium'} color="text.primary">FREE!</Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ border: '1px dashed #d6d7db' }}></Box>
                            <Grid container justifyContent="space-between" mt={1}>
                                <Grid item>
                                    <Typography variant="h5" fontWeight={'medium'}>Grand Total</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" fontWeight={'medium'}>
                                        ₹ {cart?.grandTotal.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Typography variant='h6' mb={1} mt={1} textAlign={'left'} color={'#0E4961'}>Estimated Order Arrival Time
                    </Typography>
                    <Typography variant='h5' fontSize={30} fontWeight={'bold'} mb={1} textAlign={'left'} color={'red'}>{cartReadyTime}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ textAlign: "center", marginBottom: 3, marginTop: "30px" }}
                    >
                        <Button
                            variant="contained"
                            onClick={navigateToUserDetails}
                            sx={{
                                background: "#00b0ff",
                                borderRadius: "30px",
                                fontSize: "18px",
                            }}
                        >
                            Redirecting in {seconds} seconds...
                        </Button>
                    </Typography>
                </Box>
            </Stack>
        </>
    );
};

export default OrderConfirmation;
