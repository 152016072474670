import AppLayout from "./AppLayout"
import UserLogin from "./auth/UserLogin";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import OrderConfirmation from "./screens/OrderConfirmation";
import OrderPayment from "./screens/OrderPayment";
const { Routes, Route } = require("react-router-dom")
const { default: CashierLogin } = require("./auth/CashierLogin")
const { default: Products } = require("./screens/Products");

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<AppLayout />}>
                <Route path="/" element={<CashierLogin />} />
                <Route path="/user-details" element={<UserLogin />} />
                <Route path="/products" element={<Products />} />
                <Route path="/order-payment" element={<OrderPayment />} />
                <Route path="/order-confirmation" element={<OrderConfirmation />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes