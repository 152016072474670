import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const TermsAndConditions = () => {
    const navigate = useNavigate();

    const handleBackToLogin = () => navigate('/user-details')

    return (
        <div className="max-w-4xl mx-auto text-gray-800" style={{ paddingLeft: '15px' }}>
            <h1 className="text-2xl font-bold mb-4">Xpanse Kiosk Terms of Use</h1>
            <p>By using the Xpanse Kiosk (“Kiosk”), you are entering into a legally binding agreement with Xpanse (“we,” “us,” or “our”). These Terms of Use (“Terms”) govern your use of the Kiosk. By proceeding, you confirm that you have read, understood, and agree to these Terms, as well as our Privacy Policy.</p>

            <h2 className="text-xl font-semibold mt-6">1. Updates to These Terms</h2>
            <p>We reserve the right to modify or update these Terms at any time. Should any significant changes be made, we will notify you where possible. Continued use of the Kiosk indicates acceptance.</p>

            <h2 className="text-xl font-semibold mt-6">2. Use of the Kiosk</h2>
            <h3 className="font-medium">Eligibility:</h3>
            <p>You must be at least 18 years of age to use the Kiosk. If under 18, parental or legal guardian consent is required.</p>
            <h3 className="font-medium">Minimal Information Requirement:</h3>
            <p>To use the Kiosk, you must provide your mobile number and name. Email is optional.</p>

            <h2 className="text-xl font-semibold mt-6">3. License to Use the Kiosk</h2>
            <p>You are granted a limited, non-transferable, revocable license to use the Kiosk for personal, non-commercial purposes.</p>

            <h2 className="text-xl font-semibold mt-6">4. Services Offered</h2>
            <p>The Kiosk allows you to place orders and make payments. Xpanse may modify, suspend, or discontinue any service at any time.</p>

            <h2 className="text-xl font-semibold mt-6">5. Placement of Orders</h2>
            <h3 className="font-medium">Order Placement:</h3>
            <p>Orders are placed through the Kiosk, with an on-screen confirmation and optional SMS receipt.</p>
            <h3 className="font-medium">Self-Pickup:</h3>
            <p>Orders must be collected within the specified time. Orders uncollected within 30 minutes may be canceled without refund.</p>
            <h3 className="font-medium">Cancellations:</h3>
            <p>Orders can be canceled within 30 seconds of placement. After that, cancellations may not be permitted.</p>

            <h2 className="text-xl font-semibold mt-6">6. Payment</h2>
            <p>Payments are made via the Kiosk using specified methods. Cash is not accepted. Refunds, if applicable, will be processed to the original payment method.</p>

            <h2 className="text-xl font-semibold mt-6">7. Communications</h2>
            <p>By using the Kiosk, you consent to receiving transactional and promotional communications.</p>

            <h2 className="text-xl font-semibold mt-6">8. Intellectual Property</h2>
            <p>All intellectual property rights remain with Xpanse. You may not use Xpanse’s IP without prior written consent.</p>

            <h2 className="text-xl font-semibold mt-6">9. Indemnity</h2>
            <p>You agree to indemnify and hold harmless Xpanse against claims related to your use of the Kiosk.</p>

            <h2 className="text-xl font-semibold mt-6">10. Disclaimer and Exclusion of Warranties</h2>
            <p>The Kiosk is provided “as is” without warranties. Xpanse does not guarantee uninterrupted or error-free service.</p>

            <h2 className="text-xl font-semibold mt-6">11. Limitation of Liability</h2>
            <p>Xpanse is not liable for indirect, incidental, or consequential damages.</p>

            <h2 className="text-xl font-semibold mt-6">12. Governing Law and Dispute Resolution</h2>
            <p>These Terms are governed by applicable laws, with disputes subject to exclusive jurisdiction of appropriate courts.</p>


            <h2 className="text-xl font-semibold mt-6">13. Assignment</h2>
            <p>Xpanse may assign or transfer any of its rights or obligations under these Terms, in whole or in part, at its sole discretion without prior notice.</p>

            <h2 className="text-xl font-semibold mt-6">14. Entire Agreement</h2>
            <p>These Terms, together with any policies or guidelines referenced herein, constitute the entire agreement between you and Xpanse regarding your use of the Kiosk and supersede any prior agreements or understandings.</p>

            <h2 className="text-xl font-semibold mt-6">15. Severability</h2>
            <p>If any provision of these Terms is found to be invalid, illegal, or unenforceable, that provision shall be deemed deleted, and the remainder of the Terms shall continue in full force and effect.</p>

            <h2 className="text-xl font-semibold mt-6">16. Force Majeure</h2>
            <p>Xpanse shall not be liable for any delay or failure in performance due to circumstances beyond its reasonable control, including but not limited to acts of nature, war, or government regulation.</p>

            <h2 className="text-xl font-semibold mt-6">17. Waiver</h2>
            <p>Any failure by Xpanse to enforce any provision of these Terms shall not be deemed a waiver of future enforcement of that or any other provision.</p>

            <h2 className="text-xl font-semibold mt-6">18. Rights of Third Parties</h2>
            <p>Except as expressly provided herein, no third party shall have any rights under or in connection with these Terms.</p>

            <h2 className="text-xl font-semibold mt-6">19. Customer Service</h2>
            <p>If you have any questions, contact us at <a href="mailto:contact@xpansecafe.com" className="text-blue-500">contact@xpansecafe.com</a>.</p>


            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: 2,
                }}
            >
                <Button
                    variant="contained"
                    onClick={handleBackToLogin}
                    sx={{
                        backgroundColor: "#1565C0",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        marginBottom: '10px',
                        width: "300px",
                        "&:hover": {
                            backgroundColor: "#0D47A1",
                        },
                    }}
                >
                    Back
                </Button>
            </Box>
        </div>
    );
};

export default TermsAndConditions;
